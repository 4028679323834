import './App.scss'

import { FlowerIcon } from './FlowerIcon'

function App() {
  return (
    <div className="page">
      <header className="header">
        <div className="wrapper">
          <hgroup>
            <FlowerIcon />
            <h2>In Loving Memory of</h2>
            <h1>Jane Yeh & baby</h1>
            <h3>1979 – 2021<span>|</span>2021 – 2021</h3>
          </hgroup>
          <img className="image" src="./images/jane-yeh.jpg" alt="In Loving Memory of Jane and baby Yeh" />
          <div className="desc">
            <p>Please join us on <b>Thursday, March 4 at 2:30 PM EST</b> to honor and remember them.</p>
            <p>The memorial service will be <a href="https://us02web.zoom.us/j/88613514804?pwd=MnQvWldyRm4vak9NcXQySHB1dWduUT09">
              <span>LIVE STREAMED</span></a> for everyone's safety.</p>
            <p className="zoom-link"><a href="https://us02web.zoom.us/j/88613514804?pwd=MnQvWldyRm4vak9NcXQySHB1dWduUT09"><span>https://us02web.zoom.us/j/88613514804?pwd=MnQvWldyRm4vak9NcXQySHB1dWduUT09</span></a></p>
            <p className="suttle">(Jane would not want anyone to get COVID at her funeral)</p>
          </div>
        </div>
      </header>
      <article className="video-message">
        <a href="https://app.vidhug.com/jackson-family/H1pCI1nGd/record">Share your fond memory of Jane with the Yeh Family</a>
      </article>
      <article>
        <p>Jane was a devoted wife and mother, who loved and prioritized her family.  She spent most of her life nurturing her children, taking them to school, helping them with their homework, encouraging their hobbies and expanding their horizons by taking them on events and trips.
          Jane was also an avid dog lover, having owned over a dozen dogs.  Ever the life of the party, she was quick to make friends with everyone.  Her kindness , childlike innocence and compassion won the hearts of everyone around her.  She was happily expecting her 6th child when the nightmare began.</p>
      </article>
      <article className="images">
        <figure className="one">
          <img src="./images/jackson-jane-puppy.jpg" alt="Jane with her husband Jackson and their dog" />
        </figure>
        <figure className="two">
          <img src="./images/janes-children.jpg" alt="Jane's children" />
        </figure>
      </article>
      <article>
        <p>
          Jane was admitted to the hospital on Jan 19 because she had difficulty breathing for the last 3 days.
          The hospital quickly determined that she contracted COVID and pneumonia.
          Her condition grew worse after a few days requiring that she be placed in a medically induced coma and intubated with a ventilator in the ICU... the baby was lost during this time due to the trauma.
          Jane's condition seemed stable until a week later, her blood pressure dropped in half.
          The ICU gave her vasopressors to increase her blood pressure while they tried to determine what was happening to her.
          They eventually determined that she had two hospital acquired infections:  Staph and C. Diff.  Her body went into Septic Shock.
          All of these factors contributed to multiple organ failure where her liver, kidneys, lungs and heart started to fail.
          Her husband held her hand as she took her last breath on Feb 20th, having never awakened from the coma.  She is survived by her husband and 5 young children. She will be missed by all and will forever be in our hearts.
        </p>
      </article>
      <article className="gofundme">
        In lieu of flowers or gifts, a GoFundMe has been created to assist her family during this tragedy: <a href="https://gofund.me/1e350fb5"><span>https://gofund.me/1e350fb5</span></a>
      </article>
    </div>
  )
}

export default App
